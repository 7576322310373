(function() {
	document.addEventListener("DOMContentLoaded", function(event) {
		const carousel = document.getElementById("settlement_carousel");
		if (carousel) {
			const carouselItems = carousel.querySelectorAll("li")
			let visibleItem = 0

			function swapItems() {
				for (const el of carouselItems) {
					el.classList.remove("show")
				}

				carouselItems[visibleItem].classList.add('show')
				visibleItem += 1

				if (visibleItem > carouselItems.length - 1) {
					visibleItem = 0
				}
			}

			swapItems()

			const interval = setInterval(swapItems, 5500);
		}
	})
})()