(function() {
	document.addEventListener("click", function(e) {
		if (event.target.closest(".mobile-navigation-trigger")) {
			e.preventDefault();
			document.querySelector(".main-navigation").classList.toggle("open")
		}
		
		if (event.target.closest("[data-has-dropdown]") && window.innerWidth < 1020) {
			e.preventDefault()
			const dropdownTrigger = event.target.closest("[data-has-dropdown]");
			const menu = dropdownTrigger.nextElementSibling;
			if (dropdownTrigger.parentNode.classList.contains('menu--expanded')) {
				menu.querySelector(".menu--overview").remove();
				dropdownTrigger.parentNode.classList.remove('menu--expanded')
			} else {
				const clone = dropdownTrigger.cloneNode();
				const liEl = document.createElement("li");
				delete clone.dataset.hasDropdown;
				clone.innerText = "Overview";
				liEl.classList.add('menu--overview');
				liEl.insertBefore(clone, null)
				menu.insertBefore(liEl, menu.children[0])
				dropdownTrigger.parentNode.classList.add('menu--expanded')
			}
		}
	});
	
	window.addEventListener("resize", function(e) {
		for (const el of document.querySelectorAll(".menu--expanded")) {
			el.classList.remove("menu--expanded");
		}
		for (const el of document.querySelectorAll(".menu--overview")) {
			el.remove();
		}
	});
	
	window.addEventListener("DOMContentLoaded", function(e) {
		if (!sessionStorage.getItem("language_asked")) {
			sessionStorage.setItem("language_asked", "1");
			const lang = document.documentElement.lang || "en";
			const toast = document.querySelector(".language-notification");
			if (lang === "es") {
				toast.querySelector(".language-notification--en").removeAttribute("hidden");
				toast.classList.add("language-notification--shown");
			} else {
				toast.querySelector(".language-notification--es").removeAttribute("hidden");
				toast.classList.add("language-notification--shown");
			}
		}
	});
})();